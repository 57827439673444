/* MAIN CONTAINER FOR THE HERO SECTION */
.heroSection {
  width: 100%;
  height: 100vh; // FULL VIEWPORT HEIGHT
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.videoContainer {
  position: relative;
  height: 100%;
  overflow: hidden;
}

/* BACKGROUND VIDEO SHARED STYLES */
.backgroundVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover; // ENSURES VIDEO COVERS THE ENTIRE CONTAINER
  pointer-events: none; // DISABLE INTERACTION WITH THE VIDEO
  border: none;
  z-index: -1; // POSITION BEHIND OTHER CONTENT
  transition: transform 0.5s ease;
}

/* LANDSCAPE VIDEO FOR DESKTOP SCREENS */
.landscapeVideo {
  width: 150vw;
  height: 100vh;
  transform: translate(-50%, -50%) scale(1.2); // ZOOM EFFECT FOR LARGER SCREENS
}

/* PORTRAIT VIDEO FOR MOBILE SCREENS */
.portraitVideo {
  display: none; // HIDE BY DEFAULT
}

/* RESPONSIVE ADJUSTMENTS FOR DESKTOP SCREENS */
@media (min-width: 769px) {
  .landscapeVideo {
    display: block; // SHOW LANDSCAPE VIDEO FOR DESKTOP
  }

  .portraitVideo {
    display: none; // HIDE PORTRAIT VIDEO FOR DESKTOP
  }
}

/* RESPONSIVE ADJUSTMENTS FOR MOBILE SCREENS */
@media (max-width: 768px) {
  .landscapeVideo {
    display: none; // HIDE LANDSCAPE VIDEO ON MOBILE
  }

  .portraitVideo {
    display: block; // SHOW PORTRAIT VIDEO ON MOBILE
    width: 100vw;
    height: 150vh; // EXTEND HEIGHT TO FILL SCREEN ON MOBILE
    transform: translate(-50%, -50%) scale(1.2); // ADJUST SCALE FOR MOBILE
  }
}

/* ARROW CONTAINER */
.arrowContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* DOWNWARD ARROW STYLING */
.downArrow {
  width: 60px;
  height: 60px;
  border: solid #ffffff;
  border-width: 0 8px 8px 0; // CREATES THE ARROW SHAPE
  transform: rotate(45deg); // POINTS THE ARROW DOWN
  transition: border-color 0.3s ease, transform 0.3s ease;
  animation: bounce 2s infinite; // BOUNCE EFFECT
  margin: 1rem;
}

/* ARROW SHADOW */
.downArrow::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  width: 40px;
  height: 8px;
  background: rgba(10, 10, 10, 0.8);
  transform: translateX(-50%);
  border-radius: 50%; // CREATES SOFT SHADOW
  filter: blur(6px);
  z-index: -1;
}

/* HOVER EFFECT FOR ARROW */
.downArrow:hover {
  border-color: #e6e6e6;
  transform: rotate(45deg) translateY(5px); // SLIGHT MOVEMENT ON HOVER
  filter: drop-shadow(0 0 6px rgba(255, 215, 0, 0.7)); // GLOW EFFECT
}

/* BOUNCE ANIMATION */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: rotate(45deg) translateY(0);
  }
  40% {
    transform: rotate(45deg) translateY(-10px);
  }
  60% {
    transform: rotate(45deg) translateY(-5px);
  }
}
