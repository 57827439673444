/* MAIN CONTAINER FOR ABOUT US SECTION */

.aboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0a0a;
  color: #fcfcfc;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 100;
}

/* INNER CONTAINER TO ADJUST CONTENT */
.aboutUsInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  gap: 2rem;
  box-sizing: border-box;
}

/* CONTENT CONTAINER FOR TEXT AND TITLE */
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  box-sizing: border-box;
  width: 40%;
  border-radius: 12px;
  align-items: flex-end;
  align-items: center;
}

/* STYLING FOR SECTION TITLE */
.title {
  font-size: clamp(1.8rem, 2.5vw, 2.2rem);
  margin-bottom: 1rem;
  font-family: 'Spectral', serif;
  color: #fcfcfc;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

/* STYLING FOR TEXT CONTENT */
.text {
  font-size: clamp(0.9rem, 1vw, 1.2rem);
  line-height: 1.6;
  margin-bottom: 0.8rem;
  font-family: 'Spectral', serif;
  color: #f5f5f5;
}

/* IMAGE CONTAINER STYLING */
.imageContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

/* STYLING FOR IMAGE */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

.image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
}

/* RESPONSIVE ADJUSTMENTS FOR SMALLER SCREENS */
@media (max-width: 768px) {
  .aboutUs {
    flex-direction: column;
    padding: 1.5rem;
  }

  .aboutUsInner {
    flex-direction: column;
    width: 90%;
    height: auto;
    gap: 1rem;
  }

  .content {
    width: 100%;
    padding: 1rem;
    text-align: center;
  }

  .title {
    font-size: clamp(1.4rem, 2vw, 2rem);
    margin-bottom: 0.8rem;
  }

  .text {
    font-size: clamp(0.8rem, 1vw, 1rem);
    line-height: 1.4;
  }

  .imageContainer {
    width: 100%;
    height: 40vh;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}