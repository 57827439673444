/* MAIN CONTAINER FOR THE GALLERY SECTION */
.gallerySection {
  padding: 1rem;
  background-color: #0a0a0a;
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 100;
}

/* TITLE STYLING */
.galleryTitle {
  font-size: clamp(1.8rem, 2.5vw, 2.5rem);
  margin-bottom: 1.5rem;
  font-family: 'Spectral', serif;
  color: #fcfcfc;
  text-align: center;
}

/* BENTO GRID LAYOUT FOR IMAGES */
.bentoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Flexible columns */
  gap: 0.75rem; /* Gap between images */
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  padding: 0.5rem;
  box-sizing: border-box;
}

/* BUTTON STYLING TO LOOK LIKE IMAGES */
.bentoItem {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* IMAGE STYLING */
.bentoItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* HOVER EFFECT FOR IMAGES */
.bentoItem:hover img {
  transform: scale(1.1); /* Slight zoom on hover */
}

/* MODAL OVERLAY FOR ENLARGED IMAGE */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

/* MODAL CONTENT STYLING */
.modalContent {
  position: fixed;
  max-width: 90%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0); /* Start small */
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modalContent.active {
  transform: scale(1);
  opacity: 1;
}

.modalContent.closing {
  animation: scaleDown 0.3s forwards;
}

/* IMAGE INSIDE MODAL */
.enlargedImage {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* CLOSE BUTTON */
.closeButton {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  z-index: 1001;
  transition: color 0.2s ease;
}

.closeButton:hover {
  color: #ffdd4a;
}

/* Keyframes for opening and closing animations */
@keyframes scaleDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVE ADJUSTMENTS FOR SMALLER SCREENS */
@media (max-width: 768px) {
  .bentoGrid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 0.5rem;
  }

  .galleryTitle {
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
}
/* Video Styling */
.embedVideo {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video fills the available space */
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

/* Enlarged video */
.enlargedVideo {
    height: 60vh;
    max-width: 95%;
    max-height: 90vh;
    display: block;
    margin: 0 auto;
    object-fit: fill;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Enlarged Image */
.enlargedImage {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* MODAL OVERLAY FOR ENLARGED IMAGE OR VIDEO */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

/* MODAL CONTENT STYLING */
.modalContent {
  position: fixed;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0); /* Start small */
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modalContent.active {
  transform: scale(1);
  opacity: 1;
}

.modalContent.closing {
  animation: scaleDown 0.3s forwards;
}

/* CLOSE BUTTON */
.closeButton {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  z-index: 1001;
  transition: color 0.2s ease;
}

.closeButton:hover {
  color: #ffdd4a;
}

/* Keyframes for opening and closing animations */
@keyframes scaleDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVE ADJUSTMENTS FOR SMALLER SCREENS */
@media (max-width: 768px) {
  .bentoGrid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 0.5rem;
  }

  .galleryTitle {
    font-size: clamp(1.5rem, 2vw, 2rem);
  }

  /* Enlarged video for smaller screens */
  .enlargedVideo {
    width: 100vw;
    max-height: 90vh;
  }
}
