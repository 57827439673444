.services-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  transition: all 300ms ease-in-out;
}

.services-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -120%);
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Spectral", serif;
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
  z-index: 2;
}

.services-title.fade-out {
  opacity: 0; /* Fade out when hovered */
}

.service-card {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: flex 0.6s ease, box-shadow 0.6s ease, transform 0.6s ease;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border: none;

  &:hover {
    flex: 3;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    transform: scale(1.02);
  }

  &.expanded {
    flex: 3;
  }
}

/* Overlay to center the text */
.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  z-index: 2; /* Ensure it stays above the card */
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  /* For desktop: show overlay on hover */
  @media (min-width: 769px) {
    opacity: 0; /* Initially hidden */
  }

  @media (max-width: 768px) {
    opacity: 1; /* Always visible on mobile */
  }
}

/* Service name styling */
.service-name {
  z-index: 3; /* Ensure it's on top of everything */
  font-family: "Spectral", serif;
  font-size: 2rem;
  color: #DAA435;
  transition: opacity 300ms ease-in-out, color 0.4s ease;
  pointer-events: none;

  @media (max-width: 768px) {
    opacity: 1; /* Always visible on mobile */
  }
}

/* Show service name on hover for desktop */
.service-card:hover .service-overlay {
  opacity: 1;
}

.service-card:hover .service-name {
  opacity: 1;
  transform: translateY(0);
}

/* Modal overlay and content */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Spectral", serif;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  animation: fadeIn 0.3s forwards;
}

.modal-overlay.active {
  opacity: 1;
}

.modal-content {
  background: linear-gradient(100deg, rgba(255, 215, 0, 0.49), rgba(0, 0, 0, 0.57));
  padding: 2rem;
  position: relative;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  max-width: 500px;
  color: #f5f5f5;
  filter: drop-shadow(2px 4px 6px black);
}

.close-modal-x {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 2rem;
  font-weight: bold;
  color: #f5f5f5;
  cursor: pointer;
  transition: color 200ms ease;
}

.close-modal-x:hover {
  color: #DAA435;
}

/* Fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media query for smaller screens (tooltip on the left side) */
@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    height: 100vh;
    padding: 0;
    overflow: hidden;
  }

  .service-card {
    height: calc(100vh / 4);
    transition: flex-grow 0.6s ease, height 0.6s ease;
  }

  .modal-content {
    padding: 1.5rem;
    margin: 0 1rem;
  }
}
