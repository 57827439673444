/* MAIN NAVBAR STYLES */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  z-index: 101;
  background-color: transparent;
  margin: 1rem;
}

/* CALL NOW BUTTON STYLES */
.callNow {
  padding: 0.8rem 1.2rem;
  border: 0.125rem solid #fcfcfc;
  background: linear-gradient(135deg,
      rgba(255, 215, 0, 0.3) 0%,
      rgba(46, 46, 46, 0.7) 100%),
    rgba(46, 46, 46, 0.7);
  backdrop-filter: blur(10px);
  color: #f5f5f5;
  border-radius: 8px;
  font-family: "Spectral", serif;
  font-size: 1rem;
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* FIXED CALL NOW BUTTON AT THE BOTTOM RIGHT */
.fixedCallNow {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: linear-gradient(135deg,
      rgba(255, 215, 0, 0.3) 0%,
      rgba(46, 46, 46, 0.7) 100%),
    rgba(46, 46, 46, 0.7);
  backdrop-filter: blur(10px);
  color: #f5f5f5;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  font-family: "Spectral", serif;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid rgba(255, 215, 0, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: bounce 2s infinite;
  /* ATTRACT ATTENTION WITH BOUNCE ANIMATION */
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 200;
}

/* HOVER EFFECT FOR THE FIXED CALL NOW BUTTON */
.fixedCallNow:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg,
      rgba(255, 215, 0, 0.4) 0%,
      rgba(46, 46, 46, 0.8) 100%),
    rgba(46, 46, 46, 0.8);
}

/* NAVBAR CONTENT WRAPPER */
.navbarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 90%;
  position: relative;
  z-index: 101;
}

/* LOGO LINK STYLES */
.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: auto;
  height: 100%;
  z-index: 102;
}

/* LOGO ICON STYLING */
.logoIcon {
  max-height: 85px;
  width: auto;
  transition: transform 0.25s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.4s ease,
    filter 0.4s;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}

/* LOGO HOVER EFFECT */
.logoIcon:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 0 6px rgba(255, 215, 0, 0.7));
}

/* NAVIGATION LINKS CONTAINER */
.navLinks {
  display: flex;
  align-items: center;
  gap: clamp(1rem, 2vw, 2rem);
  height: 100%;
  margin-left: auto;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease;
  z-index: 101;
}

/* INDIVIDUAL LINK ITEM STYLES */
.linkItem {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 clamp(1rem, 2vw, 1.5rem);
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  cursor: pointer;
  transition: color 0.3s, -webkit-text-stroke 0.3s, transform 0.3s;
  color: #fcfcfc;
  -webkit-text-stroke: 0px transparent;
  font-family: "Spectral", serif;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

/* LINK ITEM HOVER EFFECT */
.linkItem:hover {
  color: #fcfcfc;
  -webkit-text-stroke: 0.25px #ffd700;
  transform: translateY(-4px);
  filter: drop-shadow(0 0 6px #ffd9008c);
}

/* CONTAINER FOR CALL NOW BUTTON IN NAV LINKS */
.callNowContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

/* CALL NOW BUTTON STYLING WITHIN NAV LINKS */
.callNow {
  padding: 0 clamp(1rem, 1vw, 1.5rem);
  border: 0.125rem solid #fcfcfc;
  color: #fcfcfc;
  border-radius: 0.5rem;
  font-family: "Spectral", serif;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background-color 0.5s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.4s,
    border 0.4s, transform 0.4s;
  text-decoration: none;
}

/* HOVER EFFECT FOR CALL NOW BUTTON */
.callNow:hover {
  background-color: #000;
  color: #fcfcfc;
  border: 0.125rem solid #ffd700;
  transform: translateY(-4px);
}

/* HAMBURGER MENU BUTTON STYLES */
.hamburger {
  display: none;
    flex-direction: column;
    gap: 0.3125rem;
    height: 100%;
    border: none;
    background: rgb(0 0 0 / 0%);
    /* backdrop-filter: blur(10px); */
    border-radius: 8px;
    cursor: pointer;
    z-index: 101;
    padding: 0.5rem 1rem;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), background 0.25s ease;
}

/* HAMBURGER LINE STYLING */
.hamburgerLine {
  width: clamp(1.25rem, 2vw, 2rem);
  height: 0.1875rem;
  background-color: #fcfcfc;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 2px;
}

/* TRANSFORMATIONS FOR THE HAMBURGER LINES WHEN ACTIVE */
.hamburger.active .hamburgerLine:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.hamburger.active .hamburgerLine:nth-child(2) {
  transform: scale(0);
}

.hamburger.active .hamburgerLine:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}
.hamburger.active{
  transform: rotate(-90deg);
}

/* RESPONSIVE STYLES FOR MOBILE MENU */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
    animation: fadeIn 0.25s ease-out forwards;
    /* FADE-IN ANIMATION FOR THE HAMBURGER BUTTON */
  }

  .navLinks {
    position: fixed;
    top: -100vh;
    /* START OFF-SCREEN */
    left: clamp(5%, 6vw, 20%);
    /* ADJUST LEFT POSITION BASED ON SCREEN WIDTH */
    width: 90%;
    max-height: 20%;
    background: linear-gradient(135deg,
        rgba(255, 217, 0, 0.582) 0%,
        rgba(46, 46, 46, 0.7) 100%),
      rgba(46, 46, 46, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 215, 0, 0.4);
    z-index: 99;
    opacity: 0;
    transition: opacity 0.5s, transform 0.25s;
  }

  .navLinks.active {
    display: flex;
    top: 15%;
    opacity: 1;
    animation: slideInFromTop 0.25s ease-out forwards;
  }

  .navLinks.closing {
    display: flex;
    top: 15%;
    animation: slideOutToTop 0.25s ease-in forwards;
    /* APPLY SLIDE-OUT ANIMATION */
  }
}

/* KEYFRAMES FOR ANIMATIONS */
@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: rotate(0deg) translateY(0);
  }

  40% {
    transform: rotate(0deg) translateY(-10px);
  }

  60% {
    transform: rotate(0deg) translateY(-5px);
  }
}

@keyframes slideOutToTop {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}