/* MAIN FOOTER WRAPPER */
.footerWrapper {
  background-color: #0a0a0a; // DARK BACKGROUND TO MATCH SITE THEME
  padding: 3rem;
}

/* FOOTER CONTAINER */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fcfcfc; // TEXT COLOR FOR CONTRAST
}

/* LOGO CONTAINER */
.logoContainer {
  margin-bottom: 1rem;
  width: clamp(100px, 15vw, 150px); // LOGO SIZE WILL SCALE BASED ON SCREEN SIZE
  transition: width 0.3s ease; // SMOOTH TRANSITION FOR SIZE CHANGE
}

/* LOGO IMAGE */
.logo {
  width: 100%; // LOGO IMAGE WILL FILL THE CONTAINER SIZE
  height: auto;
  display: block;
}

/* SOCIAL MEDIA AND PHONE ICONS CONTAINER */
.iconsContainer {
  display: flex;
  gap: 1.5rem; // SPACE BETWEEN ICONS
}

/* ICON LINK STYLING */
.iconLink {
  display: flex;
  align-items: center;
}

/* ICON SIZING */
.icon {
  width: 1rem;
  height: 1rem;
}

/* COPYRIGHT TEXT */
.copyright {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #999; // LIGHTER COLOR FOR DIFFERENTIATION
  text-align: center;
}

/* MEDIA QUERY FOR SMALLER SCREENS */
@media (max-width: 768px) {
  .logoContainer {
    width: clamp(80px, 20vw, 120px); // SMALLER SIZE FOR LOGO ON SMALL SCREENS
  }
}

/* MEDIA QUERY FOR VERY LARGE SCREENS */
@media (min-width: 1200px) {
  .logoContainer {
    width: clamp(120px, 10vw, 200px); // LARGER LOGO FOR BIG SCREENS
  }
}
