/* App.scss */
.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow-x: hidden; /* Prevents horizontal overflow */
}

.App-header {
  position: relative;
  z-index: 10; /* Ensure the header content stays on top */
}

.App-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Optional: Ensure sections are styled correctly */
.Hero,
.AboutUs {
  width: 100%;
}
